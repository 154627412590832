// src/pages/preview.js
import * as React from "react"
import { withPrismicPreviewResolver } from 'gatsby-plugin-prismic-previews'
const {linkResolver} = require("../../utils/link-resolver")

const PreviewPage = ({ isPreview }) => {
  if (isPreview === false) return "Not a preview!"

  return <p>Loading</p>
}

export default withPrismicPreviewResolver(PreviewPage, [
  {
    repositoryName: 'linkfive',
    linkResolver,
  },
])